body {
  background: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

form {
  max-width: 70%;
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  color: #0e101c;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

p.error {
  color: #bf1650;
}

p.error::before {
  display: inline;
  content: 'âš  ';
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #0e101c;
  padding: 10px 15px;
  /* margin-bottom: 10px; */
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 10px;
  color: #0e101c;
  font-size: 14px;
  font-weight: 200;
}

button[type='submit'],
input[type='submit'] {
  background: #ec5990;
  color: #0e101c;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 2px;
  padding: 20px 40px;
}

button[type='submit']:hover,
input[type='submit']:hover {
  background: #bf1650;
}

button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

input[type='button']:hover {
  transition: 0.3s all;
}

button[type='submit'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

button[type='button'] {
  display: block;
  appearance: none;
  background: #333;
  color: white;
  border: none;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

hr {
  margin-top: 30px;
}

button {
  display: block;
  appearance: none;
  margin-top: 40px;
  border: 1px solid #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  padding: 10px 20px;
  border-radius: 4px;
}

.main-container {
  display: flex;
  flex-wrap: wrap;
}

.input-container {
  flex: 0 0 50%;
  padding: 0px 10px;
  box-sizing: border-box;
  width: 50%;
}

input {
  height: 42.8px;
}
.submit-button {
  cursor: pointer;
  background: #ec028c !important;
  border-color: #d3027d;
  color: #fff !important;
  text-transform: uppercase;
  border: none;
  padding: 0px !important;
  font-size: 16px !important;
  font-weight: 100;
  letter-spacing: 2px;
  border-radius: unset;
}
.submit-button:hover {
  transition: 0.3s all;
  background: #bf1650;
}

.submit-button:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}
@media only screen and (max-width: 991px) {
  form {
    max-width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .main-container {
    flex-direction: column;
  }
  .input-container {
    flex: 0 0 100%;
    width: 100%;
  }
}

input[type='checkbox'] {
  height: auto;
}
